<template>
  <ion-card class="sensor-tile">
    <ion-card-header class="text-center">
      <ion-card-title>
        <skeleton width="40%" />
      </ion-card-title>
    </ion-card-header>

    <ion-card-content class="ion-padding-horizontal ion-no-padding text-center">
      <ion-grid>
        <ion-row>
          <ion-col class="current">
            <span class="sensor-value">
              <skeleton width="25%" />
            </span>
          </ion-col>
        </ion-row>
        <ion-row class="min-max" v-if="minMax">
          <ion-col>
            <skeleton width="65%" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>

    <ion-card-header class="footer text-center" v-if="dateTime">
      <ion-card-title>
        <skeleton />
      </ion-card-title>
    </ion-card-header>
  </ion-card>
</template>

<script>
export default {
  props: {
    minMax: {
      required: false,
      type: Boolean,
      default: false,
    },
    dateTime: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
}
</script>
