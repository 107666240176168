<template>
  <ion-grid no-padding class="grouped-widgets">

    <ion-row class="full-width">
      <ion-col size="6" v-if="rootZoneSensorTypes.length">
        <div v-if="showHeaders" class="">{{ $t('sensor.zones.ROOT') }}</div>
        <div v-for="type in rootZoneSensorTypes" :key="type" :class="typeClass(type)">
          <sensor-widget :type="type" :value="typeValues[type]" />
        </div>
      </ion-col>

      <ion-col size="6" v-if="climateSensorTypes.length">
        <div v-if="showHeaders" class="">{{ $t('sensor.zones.CLIMATE') }}</div>
        <div v-for="type in climateSensorTypes" :key="type" :class="typeClass(type)">
          <sensor-widget :type="type" :value="typeValues[type]" />
        </div>
      </ion-col>
    </ion-row>

    <ion-row v-if="!anySensors ">
      <ion-col class="full-height ion-align-items-center ion-justify-content-center ion-text-center">
        <span class="text-no-sensor">{{ $t('sensor.No sensors') }}</span>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import SensorWidget from './SensorWidget'
import { SensorTypeHelper, ROOT_ZONE, CLIMATE_ZONE } from '~gro-modules/Sensor'

export default {
  components: {
    SensorWidget,
  },
  props: {
    measurements: {
      type: Array,
      required: true,
    },
    showHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
    fillSensorTypes: {
      type: Array,
      required: false,
      default () {
        return []
      },
    },
  },
  computed: {
    anySensors () {
      return this.rootZoneSensorTypes.length + this.climateSensorTypes.length !== 0
    },
    rootZoneSensorTypes () {
      return SensorTypeHelper.filterTypesForZone(this.measurementSensorTypes, ROOT_ZONE)
    },
    climateSensorTypes () {
      return SensorTypeHelper.filterTypesForZone(this.measurementSensorTypes, CLIMATE_ZONE)
    },
    measurementSensorTypes () {
      const types = this.measurements.map((item) => {
        return item.type
      }).concat(this.fillSensorTypes)
      return [...new Set(types)]
    },

    typeValues () {
      return this.measurements.reduce((acc, item) => {
        acc[item.type] = item.value
        return acc
      }, {})
    },
  },
  methods: {
    typeClass (type) {
      return SensorTypeHelper.getClassname(type)
    },
  },
}
</script>
<style lang="scss" scoped>
.text-no-sensor {
  color: var(--ion-color-light-shade)
}
</style>
