<template>
  <div class="sensor-widget"
       :class="typeClass">
    <span v-if="showLabel" class="sensor-label" v-html="typeLabel" />
    <span class="icon-egro" :class="typeClass" />
    <sensor-value v-if="value !== null"
                  :type="type"
                  :value="value"
                  no-label
                  :class="typeClass"
    />
    <span v-else
          class="no-value">-</span>
  </div>
</template>

<script>
import { SensorTypeHelper } from '~gro-modules/Sensor'
import SensorValue from './SensorValue'

export default {
  components: {
    SensorValue,
  },
  data () {
    return { svg: null }
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    showLabel: {
      default: false,
    },
  },
  computed: {
    typeClass () {
      return SensorTypeHelper.getClassname(this.type)
    },
    typeLabel () {
      return SensorTypeHelper.$t(this.type, 'label')
    },
  },
}
</script>
<style lang="scss" scoped>
.sensor-label {
  padding-right: 5px;
}

.sensor-widget {
  /* border-bottom: 3px solid #000; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // max-width: 100px;
}

.sensor-widget .no-value {
  font-style: italic;
}

.sensor-widget .no-value,
.sensor-widget .sensor-value {
  font-weight: bold;
  font-size: 16px;
}

.sensor-widget .sensor-label {
  font-size: 12px;
  min-width: 50%;
}
</style>
