<template>
  <ion-badge class="legend-sensor-toggle"
             :style="typeStyle"
             @click="updateEnabled(!enabled)">
    <img v-if="icon" :src="icon" class="icon-egro icon" v-svg-inject />
    <span v-html="label" />
  </ion-badge>
</template>

<script>
import { SensorTypeHelper } from '~gro-modules/Sensor'

export default {
  emits: ['update:enabled'],
  props: {
    type: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      internalValue: this.enabled,
    }
  },
  computed: {
    icon () {
      return SensorTypeHelper.getSetting(this.type, 'svg')
    },
    label () {
      return SensorTypeHelper.$t(this.type, 'label')
    },
    typeStyle () {
      const color = SensorTypeHelper.getColor(this.type)
      return {
        'background-color': this.internalValue ? color.value : '#d3d3de',
      }
    },
  },
  methods: {
    async updateEnabled (value) {
      this.internalValue = value
      await this.$wait(0.2)
      this.$emit('update:enabled', value)
    },
  },
}
</script>
<style lang="scss" >
.legend-sensor-toggle {
  display: inline-block;
  line-height: 30px;
  border-radius: 24px;
  padding: 0px 0.75rem;
  margin: 0 3px;
  height: 2rem;
  cursor: pointer;

  > svg .st1 {
    fill: white;
  }
  .icon {
    margin-right: 0.625rem;
  }
  span {
    vertical-align: middle
  }
}
</style>
