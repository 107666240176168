<template>
  <span class="sensor-value">
    <component v-if="customComponent" :is="customComponent" :value="value" />
    <template v-else>
        <span v-if="!noLabel" class="sensor-value-label">{{ label }}:</span>
        <span v-bind:class="typeClass" >&nbsp;{{ formattedValue }}&nbsp;</span>
        <span v-bind:class="typeClass" class="sensor-value-suffix" >{{ unit }}</span>
    </template>
  </span>
</template>

<script>
import { SensorTypeHelper } from '~gro-modules/Sensor'

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    colorized: {
      type: Boolean,
      default: true,
    },
    value: {
      required: true,
    },
  },
  data () {
    return {
      formattedValue: null,
      customComponent: null,
    }
  },
  watch: {
    value () {
      this.formatValue()
    },
  },
  computed: {
    unit () {
      return SensorTypeHelper.$t(this.type, 'unit')
    },
    label () {
      return SensorTypeHelper.$t(this.type, 'label')
    },
    typeClass () {
      if (this.colorized) {
        return SensorTypeHelper.getClassname(this.type)
      }
      return ''
    },
  },
  methods: {
    async formatValue () {
      this.formattedValue = await SensorTypeHelper.formatValueAsync(this.type, this.value)
    },
  },
  async mounted () {
    this.customComponent = await SensorTypeHelper.getSetting(this.type, 'valueComponent', false)
    this.formatValue()
  },
}
</script>
<style lang="scss" scoped>
  .sensor-value-suffix {
    font-weight: 700;
    font-size: 8px;
  }

  .sensor-value {
    font-weight: 700;
    padding-left: 5px;
    font-size: 16px;
  }

.sensor-value.desktop {
      font-weight: 500;
      color: var(--ion-color-dark);
    }

  .sensor-value-label {
    color: var(--ion-color-medium);
  }

 .sensor-value {
   .ec {
     color: var(--sensor-type-ec-color);
   }

   .root-temp {
     color: var(--sensor-type-root-temp-color);
   }

   .wc {
     color: var(--sensor-type-vwc-color);
   }

   .temp {
     color: var(--sensor-type-temp-color);
   }

   .rh {
     color: var(--sensor-type-rh-color);
   }

   .co2 {
     color: var(--sensor-type-co2-color);
   }

   .vpd {
     color: var(--sensor-type-vpd-color);
   }

   .light {
     color: var(--sensor-type-light-color);
   }
 }
</style>
