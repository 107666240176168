<template>
  <div>
    <type-toggle v-for="type in rootTypeSettings"
                 :key="type.name"
                 :type="type.name"
                 v-model:enabled="type.enabled"/>
    <type-toggle v-for="type in climateTypeSettings"
                 :key="type.name"
                 :type="type.name"
                 v-model:enabled="type.enabled"/>
  </div>
</template>

<script>
import { ROOT_ZONE, CLIMATE_ZONE } from '~gro-modules/Sensor'
import TypeToggle from '~gro-components/SensorToggle'
export default {
  components: {
    TypeToggle,
  },
  props: {
    sensorTypes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    visibleTypes () {
      return this.sensorTypes
        .filter(type => type.available)
    },
    rootTypeSettings () {
      return this.visibleTypes.filter(type => type.zone === ROOT_ZONE)
    },
    climateTypeSettings () {
      return this.visibleTypes.filter(type => type.zone === CLIMATE_ZONE)
    },
  },
}
</script>
<style lang="scss" scoped>
.legend-zone {
  display: inline-block;
  font-size: 0.669922rem;
  max-width: 45%;
  vertical-align: top;

  &:first-child:last-child {
    max-width: 100%;
  }

  .legend-zone-title {
    margin: 0 7px;
  }
}
</style>
